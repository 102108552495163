import React from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll"
import Image from 'gatsby-plugin-sanity-image';
import { motion } from 'framer-motion';

const aniVars = {
  hidden: {
    opacity: 0,
    y: 20,
    scale: 0.99,
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      delay: 0.3,
      duration: 0.5,
    },
  },
};


const PageBanner = ({  caption, heading, scrollText, image }) => {
  return (
    <>
      <div className="lg:grid lg:grid-cols-14 lg:grid-rows-1">
        <div className="bg-gray z-0 lg:row-start-1 lg:col-start-1 lg:col-end-15 relative aspect-w-9 aspect-h-6 lg:aspect-h-5 xl:aspect-h-4 2xl:aspect-h-3">
          <Image className="opacity-banner absolute inset-0 object-cover" {...image} />
        </div>
        <div className="bg-tint lg:bg-transparent px-gutter py-8 lg:pb-20 lg:text-white z-20 lg:row-start-1 lg:col-start-1 lg:col-end-8 lg:flex lg:flex-col lg:justify-end">
          <motion.div initial="hidden" animate="visible" variants={aniVars}>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-display text-brand lg:text-white">
              {heading && heading}
            </h1>
            <p className="my-4 lg:mb-6 lg:mt-2 lg:text-lg xl:text-xl leading-normal">
              {caption && caption}
            </p>
            {scrollText && (
              <AnchorLink
                href="#page-banner-anchor"
                className="self-start btn-branded lg:text-white"
              >
                {scrollText}
              </AnchorLink>
            )}
          </motion.div>
        </div>
      </div>
      <div id="page-banner-anchor"></div>
    </>
  );
}

export default PageBanner
