import React from 'react'
import { BlockWrapper } from '../blockWrapper';
import Image from 'gatsby-plugin-sanity-image';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const BioItem = ({ name, jobTitle, image, bio }) => {
  return (
    <article className="col-span-1 text-left">
      {image && (
        <div className="relative w-full aspect-w-14 aspect-h-10">
          <Image className="absolute inset-0 object-cover" {...image} />
        </div>
      )}
      <h4 className="text-lg lg:text-2xl font-display text-brand mt-6 mb-2">{name && name}</h4>
      <p class="lg:text-lg">{jobTitle && jobTitle}</p>
      {bio && <PortableTextBlock className="mt-4 prose xl:prose-lg max-w-none" text={bio} />}
    </article>
  );
}
const TeamGrid = ({ heading, gridLayout, blockConfig, items }) => {
  return (
    <BlockWrapper
      {...blockConfig}
      className={`px-gutter grid grid-cols-1 grid-flow-row gap-x-6 gap-y-12 mb-20 lg:mb-40 text-center ${gridLayout === '2' ? 'lg:grid-cols-2 xl:gap-x-16' : 'lg:grid-cols-3 lg:gap-x-12' }`}
    >
      <h3 className="col-span-full text-left text-brand font-display text-4xl lg:text-5xl lg:mb-12">
        {heading && heading}
      </h3>

      {items.map((item) => {
        return (
          <>
            {item._type === 'teamSection' ? (
              <div className="col-span-full text-left text-2xl lg:text-3xl text-brand font-display lg:mt-8">
                {item.title}
              </div>
            ) : (
              <BioItem {...item} />
            )}
          </>
        );
      })}
    </BlockWrapper>
  );
};

export default TeamGrid;
