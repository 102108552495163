import React from 'react'

import { BlockWrapper } from '../blockWrapper';
import { PortableTextBlock } from '../../sanity/portableTextBlock';

const FeaturedText = ({ blockConfig, text }) => {
  return (
    <BlockWrapper {...blockConfig} className={`grid grid-cols-1`}>
      <svg
        className="row-start-1 row-end-2 col-start-1 z-0 w-full"
        width="1440"
        height="564"
        viewBox="0 0 1440 564"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="720" cy="282" r="280.5" stroke="#F1F2F2" stroke-width="3" />
        <circle cx="102" cy="282" r="280.5" stroke="#F1F2F2" stroke-width="3" />
        <circle cx="1345" cy="282" r="280.5" stroke="#F1F2F2" stroke-width="3" />
      </svg>

      {text && (
        <PortableTextBlock
          className="px-gutter lg:px-8 prose xl:prose-lg max-w-4xl mx-auto text-center row-start-1 row-end-2 col-start-1 z-10 self-center text-brand"
          text={text}
        />
      )}
    </BlockWrapper>
  );
}

export default FeaturedText
