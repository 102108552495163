import React from 'react'

import { BlockWrapper } from '../blockWrapper';

import { CardCarousel } from '../../carousels';

import { Card } from '../../ui';

const CardSet = ({ bgTint, blockStyle, cardWidth, heading, items }) => {

  let colWrapGrid = null;
  let colWrapCarousel = null;
  if (cardWidth === 2) {
    colWrapGrid = 'md:grid-cols-2'
    colWrapCarousel = 'flex-slide-item w-full md:w-1/2 px-gutter lg:px-4';
  } else {
    colWrapGrid = 'md:grid-cols-3';
    colWrapCarousel = 'flex-slide-item w-full md:w-1/2 xl:w-1/3 px-gutter lg:px-4';
  }
    return (
      <div id="cards">
        {blockStyle === 'grid' ? (
          <BlockWrapper className={`py-12 md:py-32 px-gutter ${bgTint && 'bg-tint'}`}>
            {heading && (
              <h2 className="text-3xl lg:text-4xl xl:text-5xl font-display text-brand text-center pb-12 md:pb-20">
                {heading}
              </h2>
            )}
            <div
              className={`grid grid-cols-1 gap-y-12 gap-x-8 xl:gap-x-12 md:gap-y-24 xl:gap-y-32 ${colWrapGrid}`}
            >
              {items.map((item) => {
                return <Card key={item._id} {...item} />;
              })}
            </div>
          </BlockWrapper>
        ) : (
          <BlockWrapper
            className={`py-12 md:py-32 lg:px-gutter overflow-x-hidden ${bgTint && 'bg-tint'}`}
          >
            {heading && (
              <h2 className="text-2xl lg:text-4xl font-display text-brand text-center pb-12 md:pb-20">
                {heading}
              </h2>
            )}
            <CardCarousel>
              {items.map((item) => {
                return (
                  <div className={colWrapCarousel}>
                    <Card {...item} />
                  </div>
                );
              })}
            </CardCarousel>
          </BlockWrapper>
        )}
      </div>
    );
}

export default CardSet
