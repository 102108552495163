import React, { useCallback } from 'react'
import { motion, useAnimation } from 'framer-motion';
import Image from 'gatsby-plugin-sanity-image';
import ReactPlayer from 'react-player';
import { wrapper } from '../../media/backgroundVideo.module.css';
import { NavLink } from '../../nav/navLink';
import { FormButton } from '../../ui/formButton';

// Animation Variants
const headingVars = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      duration: 0.5,
      delay: 0.4,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
};



const HeroBanner = ({ heading, rotatingWords , video, image, links }) => {
  const playerRef = React.createRef();

  const headingControls = useAnimation();

  headingControls.start({
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      delay: 1.5,
    },
  });

  //  Hide and show text when the video ends
  const sequence = async () => {
    await headingControls.start({
      opacity: 0,
      y: 0,
      transition: {
        duration: 0.3,
      },
    });
    return await headingControls.start({
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 5.5,
      },
    });
  };

  // When video extends, replay froms start and run text sequence
  const onVideoEnd = useCallback(() => {
    playerRef.current.seekTo(0.1);
    sequence();
  }, [playerRef]);

  return (
    <>
      <div className="grid grid-cols-14 relative bg-charcoal">
        {video && (
          <div className="block row-start-1 col-start-1 col-end-15 z-0 w-full relative">
            <div className="w-full 2xl:p-0 h-screen relative">
              <div className={`${wrapper} object-cover`}>
                <ReactPlayer
                  ref={playerRef}
                  url={video}
                  playing={true}
                  muted
                  playsinline={true}
                  onEnded={() => onVideoEnd()}
                />
              </div>
            </div>
          </div>
        )}
        <>
          {image && (
            <>
              <Image
                className={`row-start-1 col-start-1 col-end-15 z-0 w-full h-screen object-cover ${
                  video && 'hidden'
                }`}
                {...image}
              />
            </>
          )}
          <motion.div
            variants={headingVars}
            initial="hidden"
            animate={headingControls}
            exit="hidden"
            className="row-start-1 col-start-2 col-end-14 flex flex-col items-center justify-center text-center z-30"
          >
            {heading && (
              <>
                <h1 className="block text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl mb-6">
                  <span className="mr-2 lg:mr-4 border-b-4 border-transparent text-white">
                    {heading}
                  </span>
                </h1>
              </>
            )}
            <FormButton />
          </motion.div>
        </>
        {links && (
          <div className="absolute z-30 bottom-4 left-0 w-full px-4 lg:px-8 xl:px-12 flex justify-center flex-nowrap gap-6 md:gap-8 xl:gap-12 font-display text-sm md:text-base lg:text-lg">
            {links.map((item) => {
              return (
                <NavLink
                  key={item.linkText}
                  className="w-full xl:text-xl border-t-2 border-brand py-3 xl:py-4 text-white text-center md:text-left"
                  {...item}
                />
              );
            })}
          </div>
        )}
      </div>
      <div id="page-banner-anchor"></div>
    </>
  );
};

export default HeroBanner;
