import React from "react"
import Image from "gatsby-plugin-sanity-image"

import { BlockWrapper } from '../blockWrapper';
import { DotsCarousel } from '../../carousels';

const SliderItem = ({ index, length, heading, blockImage }) => {
  return (
    <div className="grid grid-cols-1 w-full flex-slide-item">
      {blockImage && (
        <Image
          className="row-start-1 col-start-1 row-span-1 col-span-1 z-0 w-full h-auto max-h-screen object-cover"
          {...blockImage}
        />
      )}
      <div className="row-start-2 lg:px-4 lg:row-start-1 col-start-1 col-span-1 row-span-1 flex flex-col items-start lg:justify-end pt-6 lg:pb-12 lg:pl-12 z-10">        
        <h4 className="w-4/5 text-xl lg:text-5xl lg:text-white font-display">
          {heading && heading}
        </h4>
      </div>
    </div>
  );      
};

const ImageSlider = ({ blockConfig, items }) => {
  return (
    <BlockWrapper className="px-gutter" {...blockConfig}>
      <DotsCarousel>
        {items.map((item, index) => (
          <SliderItem index={index} length={items.length} {...item} />
        ))}
      </DotsCarousel>
    </BlockWrapper>
  );
}

export default ImageSlider
