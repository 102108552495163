import React, { useState, useEffect, useCallback } from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import { setupWheelGestures } from "embla-carousel-wheel-gestures"

// Hooks
import { useWindowSize } from '../../hooks';

const DotButton = ({ selected, onClick }) => (
  <button
    className={`w-3 h-3 mx-1 md:mx-2 rounded-full embla__dot ${selected ? 'bg-brand is-selected' : 'bg-white'}`}
    type="button"
    onClick={onClick}
  />
);
  

const DotsCarousel = ({ children, className }) => {
  const [scrollProgress, setScrollProgress] = useState(0);


  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: false,
    containScroll: 'keepSnaps',
    draggable: true,
    dragFree: false,
    slidesToScroll: 1,
  });


  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    setScrollProgress(emblaApi.scrollProgress() * 100);
  }, [emblaApi]);



  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <div className={`${className} block relative`}>
        <div
          className={`${className} overflow-x-hidden transform lg:-translate-x-4 lg:w-dotCarousel`}
          ref={emblaRef}
        >
          <div className="canvas flex">{children}</div>
          <div className="absolute z-10 bottom-8 lg:bottom-4 left-0 right-0 text-center">            
            {children.length > 1 && scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DotsCarousel;
