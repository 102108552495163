import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const StatItem = ({ className, top, number, bot, suffix }) => {

  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  

  return (
    <article ref={ref} className="col-span-1 mx-auto" style={{ maxWidth: '520px' }}>
      <h6 className="text-brand font-display mb-4">{top}</h6>
      <h4 style={{ lineHeight:'1.1'}} className={`text-3xl lg:text-4xl 2xl:text-5xl text-brand mb-2 lg:mb-6 ${className}`}>
        {number && (
          <CountUp start={0} end={inView ? number : 0}>
            {({ countUpRef }) => <span className="" ref={countUpRef} />}
          </CountUp>
        )}

        {suffix && suffix}
      </h4>
      <p style={{ maxWidth: '350px' }} className="mx-auto">
        {bot}
      </p>
    </article>
  );
};
const Statistics = ({ blockConfig, items }) => {
  return (
    <div
      {...blockConfig}
      className="px-gutter grid grid-cols-1 lg:grid-cols-3 grid-flow-row gap-x-12 gap-y-20 xl:gap-24 mb-20 lg:mb-40 text-center"
    >
      {items.map((item) => {
        return <StatItem top={item.subheading} number={item.number} suffix={item.suffix} bot={item.caption} />;
      })}
    </div>
  );
};

export default Statistics;
